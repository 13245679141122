const ApiUrl = process.env.NEXT_PUBLIC_API_URL;
const UIbaseURL = process.env.NEXT_PUBLIC_UI_URL;
const S3_BASE_URL = process.env.NEXT_PUBLIC_S3_BASE_URL;

const UrlParamsReplace = (url, params = {}) => {
  let urlWithPrefix = `${ApiUrl}${url}`;
  if (params) {
    Object.keys(params).forEach(
      (key) => (urlWithPrefix = urlWithPrefix.replace(`:${key}`, params[key]))
    );
  }
  return urlWithPrefix;
};

export const SESSIONS_URL = () => UrlParamsReplace("/sessions");

export const GET_EVENT_SUB_CATEGORIES = (slug) =>
  UrlParamsReplace("/sub-events?slug=:slug", { slug });

export const openInNewTab = (url) => {
  const newWindow = window.open(
    UIbaseURL + url,
    "_blank",
    "noopener,noreferrer"
  );
  if (newWindow) newWindow.opener = null;
};

export const downloadCSV = (url) => {
  const newWindow = window.open(ApiUrl + url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const openPDFInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const downloadDocument = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const openDocInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener");
  if (newWindow) newWindow.opener = null;
};

export const getS3FileUrl = (url) => {
  return S3_BASE_URL + "/" + url;
};
