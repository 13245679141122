/* eslint-disable no-undef */
import { postWithOutAuth } from "@services/http.service";
import { SESSIONS_URL } from "@services/url.service";
import * as HttpService from "./http.service";
const ApiUrl = process.env.NEXT_PUBLIC_API_URL;

export const login = (email, password) => {
  return postWithOutAuth(SESSIONS_URL(), { email, password });
};

export const resetPassword = (email) => {
  return HttpService.postWithOutAuth(ApiUrl + "/resetpassword", email);
};

export const verifyResetPasswordUrl = (token) => {
  return HttpService.postWithOutAuth(
    ApiUrl + "/resetpassword/verifyurl",
    token
  );
};
